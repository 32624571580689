<template>
  <div>
    <tab-headers class="mb-5">
      Manage Account
      <template #text> Manage lawyers account here </template>
    </tab-headers>

    <a-modal
      title="Reason for Suspending Account"
      :dialog-style="{ top: '20px' }"
      :visible="suspendModal"
      ok-text="Suspend"
      cancel-text="Cancel"
      @ok="actionOnUser('suspended')"
      @cancel="suspendModal = false"
    >
      <div>
        <p>Give details about why this account is suspended</p>
        <a-textarea v-model="reasonForDecline" required> </a-textarea>
      </div>
    </a-modal>

    <div
      class="
        tabs
        flex
        items-center
        justify-start
        mb-5
        rounded-md
        overflow-hidden
      "
    >
      <div
        :class="
          `px-3 py-2 text-sm cursor-pointer rounded-md ${
            activeTab === 'active' ? 'text-white bg-primary-dark' : ''
          }`
        "
        @click="activeTab = 'active'"
      >
        All Lawyers ({{ pagination.total }})
      </div>
      <div
        :class="
          `px-3 py-2 text-sm cursor-pointer rounded-md ${
            activeTab === 'active_lawyers' ? 'text-white bg-primary-dark' : ''
          }`
        "
        @click="activeTab = 'active_lawyers'"
      >
        Active ({{ activeLawyersPagination.total }})
      </div>
      <div
        :class="
          `px-3 py-2 text-sm cursor-pointer rounded-md ${
            activeTab === 'pending' ? 'text-white bg-primary-dark' : ''
          }`
        "
        @click="activeTab = 'pending'"
      >
        Pending ({{ pendingLawyersPagination.total }})
      </div>
      <div
        :class="
          `px-3 py-2 text-sm cursor-pointer rounded-md ${
            activeTab === 'suspended' ? 'text-white bg-primary-dark' : ''
          }`
        "
        @click="activeTab = 'suspended'"
      >
        Suspended Accounts ({{ suspendedLawyersPagination.total }})
      </div>
      <div
        :class="
          `px-3 py-2 text-sm cursor-pointer rounded-md ${
            activeTab === 'disapproved' ? 'text-white bg-primary-dark' : ''
          }`
        "
        @click="activeTab = 'disapproved'"
      >
        Disapproved Accounts ({{ disapprovedLawyersPagination.total }})
      </div>
    </div>

    <div v-show="activeTab === 'active'">
      <div class="grid md:grid-cols-4 grid-cols-2 gap-5">
        <div>
          <p class="mb-0 ml-2 text-sm">Search...</p>
          <a-input
            v-model="searchKeyword"
            placeholder="Search..."
            @change="debounceSearch"
          ></a-input>
        </div>
        <div>
          <p class="mb-0 ml-2 text-sm">Filter By</p>
          <a-select
            v-model="searchBy"
            style="width: 100%;"
            placeholder="Search by"
          >
            <a-select-option value="firstName">First Name</a-select-option>
            <a-select-option value="lastName">Last Name</a-select-option>
            <a-select-option value="email">Email</a-select-option>
          </a-select>
        </div>
        <div>
          <p class="mb-0 ml-2 text-sm">Filter State</p>
          <a-select
            v-model="searchByState"
            style="width: 100%;"
            :loading="states.length === 0"
            placeholder="Select a state"
            :filter-option="filterOptionState"
            @change="getAllUsers"
          >
            <a-select-option
              v-for="state in states"
              :key="state._id"
              :value="state._id"
            >
              {{ state.title }}
            </a-select-option>
          </a-select>
        </div>
        <div>
          <p class="mb-0 ml-2 text-sm">Filter Practitioner</p>
          <a-select
            v-model="searchByParticipation"
            style="width: 100%"
            :filter-option="filterOptionState"
            @change="getAllUsers"
            placeholder="Practitioner Type"
          >
            <a-select-option
              v-for="p in practitioner"
              :key="p.id"
              :value="p.id"
            >
              {{ p.title }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="mt-5 bg-white p-2 rounded shadow">
        <a-table
          :pagination="pagination"
          :row-key="record => record._id"
          :loading="gettingUser"
          :columns="headers"
          :data-source="lawyers"
          @change="getAllUsers"
        >
          <span slot="action" slot-scope="record">
            <a-button @click="showDrawer(record)">View </a-button>
          </span>
          <span slot="state" slot-scope="record">
            {{ record.state ? record.state.title : 'No state in Profile' }}
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="verified" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </span>
        </a-table>
      </div>
    </div>
    <div v-show="activeTab === 'active_lawyers'">
      <div class="mt-5 bg-white p-2 rounded shadow">
        <a-table
          :pagination="activeLawyersPagination"
          :row-key="record => record._id"
          :loading="gettingActiveUsers"
          :columns="headers"
          :data-source="activeLawyers"
          @change="getAllActiveUsers"
        >
          <span slot="state" slot-scope="record">
            {{ record.state ? record.state.title : 'No state in Profile' }}
          </span>
          <span slot="action" slot-scope="record">
            <a-button @click="showDrawer(record)">View </a-button>
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="verified" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </span>
        </a-table>
      </div>
    </div>
    <div v-show="activeTab === 'pending'">
      <div class="mt-5 bg-white p-2 rounded shadow">
        <a-table
          :pagination="pendingLawyersPagination"
          :row-key="record => record._id"
          :loading="gettingPendingUsers"
          :columns="headers"
          :data-source="pendingLawyers"
          @change="getAllPendingUsers"
        >
          <span slot="state" slot-scope="record">
            {{ record.state ? record.state.title : 'No state in Profile' }}
          </span>
          <span slot="action" slot-scope="record">
            <a-button @click="showDrawer(record)">View </a-button>
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="verified" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </span>
        </a-table>
      </div>
    </div>
    <div v-show="activeTab === 'suspended'">
      <div class="mt-5 bg-white p-2 rounded shadow">
        <a-table
          :pagination="suspendedLawyersPagination"
          :row-key="record => record._id"
          :loading="gettingSuspendedUser"
          :columns="headers"
          :data-source="suspendedLawyers"
          @change="getAllSuspendedUsers"
        >
          <span slot="state" slot-scope="record">
            {{ record.state ? record.state.title : 'No state in Profile' }}
          </span>
          <span slot="action" slot-scope="record">
            <a-button @click="showDrawer(record)">View </a-button>
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="verified" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </span>
        </a-table>
      </div>
    </div>
    <div v-show="activeTab === 'disapproved'">
      <div class="mt-5 bg-white p-2 rounded shadow">
        <a-table
          :pagination="disapprovedLawyersPagination"
          :row-key="record => record._id"
          :loading="gettingDisapprovedUsers"
          :columns="headers"
          :data-source="disapprovedLawyers"
          @change="getAllDisapprovedUsers"
        >
          <span slot="state" slot-scope="record">
            {{ record.state ? record.state.title : 'No state in Profile' }}
          </span>
          <span slot="action" slot-scope="record">
            <a-button @click="showDrawer(record)">View </a-button>
          </span>
          <span slot="status" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.status }}
            </div>
          </span>
          <span slot="verified" slot-scope="record">
            <div
              :class="
                `
          border py-1 px-3 text-center rounded
            ${
              record.status === 'active'
                ? 'bg-green-50 text-green-500 border-green-500'
                : record.status === 'pending'
                ? 'bg-yellow-50 text-yellow-500 border-yellow-500'
                : 'bg-red-50 text-red-500 border-red-500'
            }`
              "
            >
              {{ record.isVerified ? 'Verified' : 'Not Verified' }}
            </div>
          </span>
        </a-table>
      </div>
    </div>

    <a-drawer
      :visible="showSubscribe"
      width="550"
      placement="right"
      message="Subscribe"
      @close="showSubscribe = false"
    >
      <h2 class="font-semibold text-lg mb-3">Subscribe a User</h2>
      <p>Select a plan</p>
      <a-select v-model="selectedPlan" class="w-full">
        <a-select-option
          v-for="subs in subscriptions"
          :key="subs._id"
          :value="subs._id"
        >
          {{ subs.title }}
        </a-select-option>
      </a-select>
      <div class="flex items-center justify-end mt-5">
        <a-button
          :loading="subscribingUser"
          type="primary"
          @click="subscribeUser"
          >Subscribe User</a-button
        >
      </div>
    </a-drawer>
    <a-drawer
      width="550"
      placement="right"
      closable
      :visible="visible"
      @close="onClose"
    >
      <a-avatar :size="105" :src="lawyer.avatar" />
      <h3 class="text-2xl capitalize mb-5">{{ lawyer.lastName }}'s Profile</h3>
      <div class="grid md:grid-cols-2 grid-cols-1">
        <div>
          <p v-if="lawyer.isSubscribed" class="font-semibold">
            This user has a active Subscription
          </p>
          <p v-else class="font-semibold">
            This user doesn't have a active Subscription
          </p>
        </div>
        <div>
          <p v-if="lawyer.isListed" class="font-semibold">
            This user has a active Listing plan
          </p>
          <p v-else class="font-semibold">
            This user doesn't have a active Listing plan of
            {{ lawyer.listingType }}
          </p>
        </div>
        <div>
          <p class="mb-0">First Name</p>
          <p class="font-semibold">{{ lawyer.firstName }}</p>
        </div>
        <div>
          <p class="mb-0">Last Name</p>
          <p class="font-semibold">{{ lawyer.lastName }}</p>
        </div>
        <div>
          <p class="mb-0">Other Name</p>
          <p class="font-semibold">{{ lawyer.otherName }}</p>
        </div>
        <div class="col-span-2">
          <p class="mb-0">Email</p>
          <p class="font-semibold">{{ lawyer.email }}</p>
        </div>
        <div>
          <p class="mb-0">Gender</p>
          <p class="font-semibold capitalize">{{ lawyer.gender }}</p>
        </div>
        <div>
          <p class="mb-0">Date of Birth</p>
          <p class="font-semibold capitalize">{{ lawyer.dateOfBirth }}</p>
        </div>
        <div>
          <p class="mb-0">Phone</p>
          <p class="font-semibold">
            {{ lawyer.phone }}
          </p>
        </div>
        <div>
          <p class="mb-0">Registered On</p>
          <p class="font-semibold">
            {{ new Date(lawyer.createdAt).toDateString() }}
          </p>
        </div>
        <div>
          <p class="mb-0">Last Profile Update</p>
          <p class="font-semibold">
            {{ new Date(lawyer.updatedAt).toDateString() }}
          </p>
        </div>
        <div class="col-span-2">
          <p class="mb-0">Areas of Practices</p>
          <div class="flex flex-wrap">
            <span
              v-for="practice in lawyer.areasOfPractice"
              :key="practice._id"
              class="text-sm px-2 py-1 mx-1 my-1 capitalize"
              >{{ practice.practice.title }}</span
            >
          </div>
        </div>

        <div>
          <p class="mb-0">State</p>
          <p class="font-semibold">
            {{ lawyer.state ? lawyer.state.title : '' }}
          </p>
        </div>
        <div>
          <p class="mb-0">Town</p>
          <p class="font-semibold">
            {{ lawyer.town ? lawyer.town.title : '' }}
          </p>
        </div>
        <div>
          <p class="mb-0">Law Firm</p>
          <p class="font-semibold">{{ lawyer.lawFirm }}</p>
        </div>

        <div>
          <p class="mb-0">Year called to Bar</p>
          <p class="font-semibold">{{ lawyer.callToBarYear }}</p>
        </div>
        <div>
          <p class="mb-0">Business Address</p>
          <p class="font-semibold">{{ lawyer.businessAddress }}</p>
        </div>
        <div v-if="lawyer.proofOfPayment">
          <p class="mb-0">Download Proof of Payment</p>
          <template v-if="checkLinkType(lawyer.proofOfPayment) === 'image'">
            <a download target="_blank" :href="lawyer.proofOfPayment"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(lawyer.proofOfPayment, lawyer, 'Payment Of Proof')
              "
              >Download</a
            >
          </template>
        </div>
        <div v-if="lawyer.callToBarCertificate">
          <p class="mb-0">Download Call to Bar Certificate</p>
          <template
            v-if="checkLinkType(lawyer.callToBarCertificate) === 'image'"
          >
            <a download target="_blank" :href="lawyer.callToBarCertificate"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(
                  lawyer.callToBarCertificate,
                  lawyer,
                  'Call to Bar Certificate'
                )
              "
              >Download</a
            >
          </template>
        </div>
        <div
          v-if="lawyer.otherCertificates && lawyer.otherCertificates.length > 0"
          class="mt-3"
        >
          <p class="mb-0">Download Other Professional Certificate</p>
          <template
            v-if="checkLinkType(lawyer.otherCertificates[0]) === 'image'"
          >
            <a download target="_blank" :href="lawyer.otherCertificates[0]"
              >Download</a
            >
          </template>
          <template v-else>
            <a
              download
              target="_blank"
              @click="
                downloadFile(
                  lawyer.otherCertificates[0],
                  lawyer,
                  'Other Certificate'
                )
              "
              >Download</a
            >
          </template>
        </div>
      </div>
      <div class="pt-10"></div>
      <div>
        <a-button
          :style="{ marginRight: '8px' }"
          :disabled="actionOnUserLoading"
          @click="onClose"
        >
          Cancel
        </a-button>

        <a-button
          type="error"
          :disabled="
            lawyer.status === 'suspended' ||
              lawyer.status === 'disapproved' ||
              actionOnUserLoading
          "
          :loading="actionOnUserLoading"
          @click="suspendModal = true"
        >
          Suspend
        </a-button>
        <a-button
          type="primary"
          :loading="actionOnUserLoading"
          @click="showSubscribe = true"
        >
          Subscribe
        </a-button>
        <a-button
          type="primary"
          :disabled="lawyer.status === 'active' || actionOnUserLoading"
          @click="actionOnUser('active')"
        >
          Activate
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import _ from 'lodash'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import usersApi from '../../api/users'
import subscriptionApi from '../../api/subscription'
import siteDataApi from '../../api/site_data'

const headers = [
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName'
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName'
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'State',
    key: 'state',
    scopedSlots: { customRender: 'state' }
  },
  {
    title: 'Status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend']
  },
  {
    title: 'Action',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: 'LawyerChecker',
  components: { TabHeaders },
  layout: 'admindashboard',
  data() {
    return {
      practitioner: [
        {
          id: 'lawyer',
          title: 'Lawyer'
        },
        {
          id: 'lawfirm',
          title: 'Law Firm'
        }
      ],
      activeTab: 'active',
      headers,
      suspendModal: false,
      searchKeyword: '',
      searchBy: 'lastName',
      gettingUser: false,
      gettingDisapprovedUsers: false,
      gettingSuspendedUser: false,
      gettingDisapprovedUser: false,
      gettingPendingUsers: false,
      gettingActiveUsers: false,
      lawyers: [],
      activeLawyers: [],
      pendingLawyers: [],
      suspendedLawyers: [],
      disapprovedLawyers: [],
      lawyer: {},
      visible: false,
      subscribingUser: false,
      showSubscribe: false,
      actionOnUserLoading: false,
      subscriptions: [],
      selectedPlan: '',
      states: [],
      searchByState: '',
      searchByParticipation: '',
      reasonForDecline: '',
      pagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0
      },
      pendingLawyersPagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0
      },
      activeLawyersPagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0
      },
      suspendedLawyersPagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0
      },
      disapprovedLawyersPagination: {
        limit: 40,
        page: 40,
        defaultPageSize: 40,
        offset: 0
      }
    }
  },
  created() {
    this.getAllUsers(this.pagination)
    this.getAllSuspendedUsers(this.suspendedLawyersPagination)
    this.getAllDisapprovedUsers(this.disapprovedLawyersPagination)
    this.getAllPendingUsers(this.pendingLawyersPagination)
    this.getAllActiveUsers(this.activeLawyersPagination)
    this.debounceSearch = _.debounce(this.searchClient, 500)
    this.getPlans()
    this.getStates()
    this.activeTab = this.$route.query.active_tab || 'active'
  },
  // watch: {
  //   searchKeyword() {
  //     this.debounceSearch()
  //   },
  //   searchByState() {
  //     this.getAllUsers(this.pagination)
  //   }
  // },
  methods: {
    async searchClient() {
      if (this.searchKeyword === '') {
        this.getAllUsers(this.pagination)
        return
      }
      this.gettingUser = true
      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        {
          keyword: this.searchKeyword,
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: 0,
          offset: 0,
          order: 'asc',
          orderBy: this.searchBy
        }
      )
      // this.lawyers = req.data.data.filter(user => user.status !== 'pending')
      this.lawyers = req.data.data
      this.gettingUser = false
    },
    async getAllUsers(pagination) {
      this.gettingUser = true
      this.pagination.offset = (pagination.current - 1) * pagination.limit

      const params = {
        role: 'lawyer',
        populate: ['state', 'town', 'areasOfPractice.practice'],
        limit: this.pagination.limit,
        offset: this.pagination.offset,
        order: 'asc',
        orderBy: 'lastName'
      }
      if (this.searchByState) {
        params.state = this.searchByState
      }

      if (this.searchByParticipation) {
        params.role = this.searchByParticipation
      }

      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        params
      )
      this.lawyers = req.data.data
      // this.lawyers = req.data.data
      this.pagination = { ...this.pagination, ...req.data.meta }
      this.pagination.page =
        Math.ceil(this.pagination.total / this.pagination.limit) === Infinity
          ? 0
          : Math.ceil(this.pagination.total / this.pagination.limit)

      this.gettingUser = false
    },
    async getAllPendingUsers(pagination) {
      this.gettingPendingUsers = true

      this.pendingLawyersPagination.offset =
        (pagination.current - 1) * pagination.limit
      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        {
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: this.pendingLawyersPagination.limit,
          offset: this.pendingLawyersPagination.offset,
          order: 'asc',
          orderBy: 'lastName',
          status: 'pending'
        }
      )
      this.pendingLawyers = req.data.data
      // this.lawyers = req.data.data
      this.pendingLawyersPagination = {
        ...this.pendingLawyersPagination,
        ...req.data.meta
      }

      this.gettingPendingUsers = false
    },
    async getAllActiveUsers(pagination) {
      this.gettingActiveUsers = true

      this.activeLawyersPagination.offset =
        (pagination.current - 1) * pagination.limit
      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        {
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: this.activeLawyersPagination.limit,
          offset: this.activeLawyersPagination.offset,
          order: 'asc',
          orderBy: 'lastName',
          status: 'active'
        }
      )
      this.activeLawyers = req.data.data
      // this.lawyers = req.data.data
      this.activeLawyersPagination = {
        ...this.activeLawyersPagination,
        ...req.data.meta
      }

      this.gettingActiveUsers = false
    },
    async getAllSuspendedUsers(pagination) {
      this.gettingSuspendedUser = true

      this.suspendedLawyersPagination.offset =
        (pagination.current - 1) * pagination.limit
      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        {
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: this.suspendedLawyersPagination.limit,
          offset: this.suspendedLawyersPagination.offset,
          order: 'asc',
          orderBy: 'lastName',
          status: 'suspended'
        }
      )
      this.suspendedLawyers = req.data.data.filter(
        user => user.status !== 'pending'
      )
      // this.lawyers = req.data.data
      this.suspendedLawyersPagination = {
        ...this.suspendedLawyersPagination,
        ...req.data.meta
      }
      this.suspendedLawyersPagination.page =
        Math.ceil(
          this.suspendedLawyersPagination.total /
            this.suspendedLawyersPagination.limit
        ) === Infinity
          ? 0
          : Math.ceil(
              this.suspendedLawyersPagination.total /
                this.suspendedLawyersPagination.limit
            )

      this.gettingSuspendedUser = false
    },
    async getAllDisapprovedUsers(pagination) {
      this.gettingDisapprovedUsers = true
      this.disapprovedLawyersPagination.offset =
        (pagination.current - 1) * pagination.limit
      const req = await usersApi(this.axios).getAllUser(
        { role: 'lawyer' },
        {
          populate: ['state', 'town', 'areasOfPractice.practice'],
          limit: this.disapprovedLawyersPagination.limit,
          offset: this.disapprovedLawyersPagination.offset,
          order: 'asc',
          orderBy: 'lastName',
          status: 'disapproved'
        }
      )
      this.disapprovedLawyers = req.data.data.filter(
        user => user.status !== 'pending'
      )
      // this.lawyers = req.data.data
      this.disapprovedLawyersPagination = {
        ...this.disapprovedLawyersPagination,
        ...req.data.meta
      }
      this.disapprovedLawyersPagination.page =
        Math.ceil(
          this.disapprovedLawyersPagination.total /
            this.disapprovedLawyersPagination.limit
        ) === Infinity
          ? 0
          : Math.ceil(
              this.disapprovedLawyersPagination.total /
                this.disapprovedLawyersPagination.limit
            )

      this.gettingDisapprovedUsers = false
    },
    async actionOnUser(action) {
      this.actionOnUserLoading = true

      const req = await usersApi(this.axios).updateUserStatus({
        userId: this.lawyer._id,
        status: action,
        reason: this.reasonForDecline
      })
      this.suspendModal = false
      if (req.error) {
        this.$notification.error({
          message: 'Unable to suspend account',
          description: req.message
        })
        this.reasonForDecline = ''
        this.actionOnUserLoading = false
        return
      }
      this.$notification.success({
        message: `Account ${action}`,
        description: req.message
      })
      this.reasonForDecline = ''
      this.actionOnUserLoading = false
      this.getAllUsers()
      this.getAllSuspendedUsers()
      this.getAllDisapprovedUsers()
      this.onClose()
    },
    showDrawer(lawyer) {
      this.lawyer = lawyer
      this.visible = true
    },
    onClose() {
      this.visible = false
      this.lawyer = {}
    },
    async getPlans() {
      const req = await subscriptionApi(this.axios).getPlans()
      this.subscriptions = req.data.data
    },
    async subscribeUser() {
      this.subscribingUser = true
      const req = await subscriptionApi(this.axios).subscribeAUser({
        planId: this.selectedPlan,
        userId: this.lawyer._id
      })
      if (req.error) {
        this.$notification.error({
          message: "Can't subscribe user",
          description: req.message
        })
        this.subscribingUser = false
        return
      }
      this.$notification.success({
        message: 'User Subscribed Successful',
        description: req.message
      })
      this.subscribingUser = false
      this.showSubscribe = false
    },
    checkLinkType(link) {
      const imagesFormat = [
        '.jpg',
        '.jpeg',
        '.jfif',
        '.pjpeg',
        '.pjp',
        '.png',
        '.svg'
      ]
      return imagesFormat.some(format => link.toString().includes(format))
        ? 'image'
        : 'file'
    },
    downloadFile(link, user, docType) {
      window.URL = window.URL || window.webkitURL

      const xhr = new XMLHttpRequest()
      const a = document.createElement('a')
      let file

      xhr.open('GET', link, true)
      xhr.responseType = 'blob'
      xhr.onload = function() {
        file = new Blob([xhr.response], { type: 'application/octet-stream' })
        a.href = window.URL.createObjectURL(file)
        a.classList.add('.hidden')
        a.download = `${docType}_${user.firstName}_${user.lastName}.pdf` // Set to whatever file name you want
        // Now just click the link you created
        // Note that you may have to append the a element to the body somewhere
        // for this to work in Firefox
        document.getElementsByTagName('body')[0].appendChild(a)
        a.click()
      }
      xhr.send()
    },
    async getStates() {
      const req = await siteDataApi(this.axios).get(
        { type: 'state' },
        { limit: 0 }
      )
      this.states = req.data.data
    },
    filterOptionState(input, option) {
      return option.componentOptions.children[0].text
        .toLowerCase()
        .includes(input.toLowerCase())
    }
  }
}
</script>
